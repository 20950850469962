import React, { useEffect } from 'react'
import styled from 'styled-components'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { H1, P } from '../shared/text'
import { media } from '../utils/style-utils'

const Container = styled.div`
  background-color: ${props => props.theme.colors.gandalf};
  color: ${props => props.theme.colors.azure};
  
  max-width: 1198px;
  margin: 0 auto;

  display: grid;
  place-items: center;

  ${media.small`
    width: 90vw;
    padding: 70px 24px 55px 51px;
  `}
  ${media.medium`
    width: 100%;
    padding: 100px 40px 55px 40px;
  `}
  ${media.large`
    padding: 72px 80px 101px 80px;
  `}
`

const HeaderWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;

  display: grid;
  grid-row-gap: 20px;

  & h1::after {
    display: none;
  }

  & p {
    max-width: 683px;
  }
`

const TwoColGrid = styled.div`
  display: grid;

  ${media.small`
    margin-top: 35px;
  `}
  ${media.medium`
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 45px;
  `}
  ${media.large`
    grid-template-columns: max-content max-content;
    grid-column-gap: 65px;
    margin-top: 60px;
  `}

  & p {
    ${media.small`
      font-size: 16px;
    `}
    ${media.medium`
      font-size: 16px;
    `}
    ${media.large`
      font-size: 24px;
    `}
  }
`

const BulletGrid = styled.ul`
  height: fit-content;

  display: grid;

  ${media.medium`
    max-width: 280px;
  `}
  ${media.large`
    max-width: 454px;
  `}
`

const BulletRow = styled.li`
  text-decoration: none;
  list-style: none;

  &:before {
    color: ${props => props.theme.colors.healerite};
    content: '•';
    font-size: 36px;
    position: absolute;
    margin: -4px 0 0 -24px;
  }
`

const perks = {
  listOne: [
    'Bright office in the heart of Soho',
    'Unlimited vacation & Summer Fridays',
    '10 paid holidays',
    'Pet-friendly office',
    '401(k) plans',
    'Parental leave package',
  ],
  listTwo: [
    'Semi-remote policy enabling remote work for over half the year',
    'Quarterly team-building events',
    'Corporate gym benefits',
    'Weekly breakfast and lunch',
    'Medical, vision, and dental insurance',
    'Commuter and FSA/HSA benefits',
  ],
}

const Perks = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const tl = gsap.timeline({
      defaults: { opacity: 0, duration: 0.75, y: 20 },
      scrollTrigger: '.perks__title',
    })
    tl.to('.perks__title, .perks__subtitle, .perks__value-group', {
      visibility: 'visible',
    })
    tl.from('.perks__title', {})
    tl.from('.perks__subtitle', {}, '<0.15')
    tl.from('.perks__bullets', { y: 0 })
  }, [])

  return (
    <Container>
      <HeaderWrapper>
        <H1 className="perks__title">Work perks</H1>
        <P className="perks__subtitle">
          Loving the work you do is a perk in itself, but a few extra benefits
          never hurt - that’s why we offer:
        </P>
      </HeaderWrapper>
      <TwoColGrid className="perks__bullets">
        <BulletGrid>
          {perks.listOne.map(perk => (
            <BulletRow key={perk}>
              <P>{perk}</P>
            </BulletRow>
          ))}
        </BulletGrid>
        <BulletGrid>
          {perks.listTwo.map(perk => (
            <BulletRow key={perk}>
              <P>{perk}</P>
            </BulletRow>
          ))}
        </BulletGrid>
      </TwoColGrid>
    </Container>
  )
}

export default Perks
