import React from 'react'

import SEO from '../components/shared/SEO'
import Layout from '../components/Layout'
import Careers from '../components/Careers'

const CareersPage = ({ location }) => {
  return (
    <>
      <SEO title="Join Us" description="Great ideas are nothing without a great team to bring them to life" />
      <Layout location={location}>
        <Careers />
      </Layout>
    </>
  )
}

export default CareersPage
