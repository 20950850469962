import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { H1, LargeP, P, SmallP } from '../shared/text'
import { media } from '../utils/style-utils'

const Container = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  text-align: center;

  color: ${props => props.theme.colors.azure};

  ${media.small`
    padding: 70px 36px 0 36px;
  `}
  ${media.medium`
    padding: 70px 50px 0 50px;
  `}
  ${media.large`
    padding: 90px 10px 0 10px;
    max-width: 1198px;
  `}

  & > h1 {
    visibility: hidden;

    margin-bottom: 20px;

    &::after {
      display: none;
    }
  }

  & > p {
    visibility: hidden;

    max-width: 625px;
  }
`

const Grid = styled.div`
  width: 100%;

  max-width: 1268px;

  display: grid;

  ${media.small`
    padding: 50px 0 60px 0;
    grid-gap: 75px;  
    place-items: center;
  `}
  ${media.medium`
    padding: 68px 0 55px 0;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
  `}
  ${media.large`
    padding: 80px 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 100px 80px; 
  `}
`

const ValueGroup = styled.div`
  visibility: hidden;

  width: 100%;

  display: grid;
  grid-template-rows: 55px auto auto;
  grid-row-gap: 13px;

  ${media.small`
    place-items: center;
    text-align: center; 
  `}
  ${media.medium`
    justify-items: start;
    text-align: left; 
  `}
`

const GreenLargeP = styled(LargeP)`
  color: ${props => props.theme.colors.healerite};
  line-height: 1;
`

const IconImg = styled.div`
  height: 100%;
  width: 100%;
  max-height: 55px;
  max-width: 56px;

  margin-bottom: 20px;

  display: flex;
  justify-content: flex-start;
`

const query = graphql`
  query {
    collaboration: file(
      relativePath: { regex: "/careers/icons/collaboration.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ownership: file(relativePath: { regex: "/careers/icons/ownership.png/" }) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    transparency: file(
      relativePath: { regex: "/careers/icons/transparency.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    grit: file(relativePath: { regex: "/careers/icons/grit.png/" }) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    results: file(relativePath: { regex: "/careers/icons/results.png/" }) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    adaptability: file(
      relativePath: { regex: "/careers/icons/adaptability.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const AboveTheFold = () => {
  const {
    collaboration,
    ownership,
    transparency,
    grit,
    results,
    adaptability,
  } = useStaticQuery(query)

  const values = [
    {
      title: 'Collaboration',
      description:
        'Repeatable success requires sharing time, ideas, and resources. We’re all about working together to produce the best possible results with our collective skills, talents, and creativity.',
      imgFluid: collaboration.childImageSharp.fluid,
    },
    {
      title: 'Ownership',
      description:
        'Our team takes pride in the work that we do, and we’re always striving to leave things better than we found them.',
      imgFluid: ownership.childImageSharp.fluid,
    },
    {
      title: 'Transparency',
      description:
        'We aim to create a workplace that champions openness and trust between our team members. To that end, we always strive for thoughtful, honest communication.',
      imgFluid: transparency.childImageSharp.fluid,
    },
    {
      title: 'Grit',
      description:
        'Sometimes, the most challenging tasks bring the greatest rewards. That’s why we’re all about perseverance, resilience, and plenty of creative problem-solving in the face of obstacles.',
      imgFluid: grit.childImageSharp.fluid,
    },
    {
      title: 'Result-Driven',
      description:
        'We know that long-term payoffs are more valuable than quick gains, so we work hard to maximize output and efficiency while maintaining the highest standard of quality.',
      imgFluid: results.childImageSharp.fluid,
    },
    {
      title: 'Adaptability',
      description:
        'Business is always changing, which means that we need to be, too. We stay flexible and are ready to pivot when necessary to make sure that we’re always one step ahead of the game.',
      imgFluid: adaptability.childImageSharp.fluid,
    },
  ]

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const tl = gsap.timeline({
      defaults: { opacity: 0, duration: 0.75, y: 20 },
      scrollTrigger: '.values__title',
    })
    tl.to('.values__title, .values__subtitle, .values__value-group', {
      visibility: 'visible',
    })
    tl.from('.values__title', {})
    tl.from('.values__subtitle', {}, '<0.15')
    tl.from('.values__value-group', { stagger: 0.15, duration: 1, y: 0 })
  }, [])

  return (
    <Container>
      <H1 className="values__title">Values are everything</H1>
      <P className="values__subtitle">
        Our core values define who we are and the workplace environment we
        cultivate.
      </P>
      <Grid>
        {values.map(value => (
          <ValueGroup className="values__value-group" key={value.title}>
            <IconImg>
              <Img
                fluid={value.imgFluid}
                style={{ height: '100%', width: '100%' }}
                imgStyle={{ objectFit: 'contain' }}
                aria-hidden
              />
            </IconImg>
            <GreenLargeP>{value.title}</GreenLargeP>
            <SmallP>{value.description}</SmallP>
          </ValueGroup>
        ))}
      </Grid>
    </Container>
  )
}

export default AboveTheFold
