import React from 'react'
import styled from 'styled-components'

import AboveTheFold from './AboveTheFold'
import Values from './Values'
import Perks from './Perks'
import Opportunities from './Opportunities'

const Container = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
`

const Careers = () => {
  return (
    <Container>
      <AboveTheFold />
      <Values />
      <Perks />
      <Opportunities />
    </Container>
  )
}

export default Careers
