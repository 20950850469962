import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { Button } from '../shared/buttons'
import { H1, LargeP, P, Gotham } from '../shared/text'
import { media } from '../utils/style-utils'

const Container = styled.div`
  width: 100%;

  display: grid;
  place-items: center;
  text-align: center;

  color: ${props => props.theme.colors.azure};

  ${media.small`
    padding: 40px 25px 62px 25px;

    & h1 {
      margin-bottom: 40px;
    }
  `}
  ${media.medium`
    padding: 40px 20px 62px 20px;
  `}
  ${media.large`
    text-align: left;
    padding: 90px 40px;
    min-height 600px;
    & h1 {
      margin-bottom: 70px;
    }
  `}

  & h1 {
    visibility: hidden;

    &::after {
      display: none;
    }
  }
`

const JobListingGrid = styled.div`
  width: 100%;
  margin: 0 auto;

  display: grid;
  place-items: center;

  ${media.medium`
    max-width: 506px;
  `}
  ${media.large`
    max-width: 954px;
  `}
`

const JobRow = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;

  text-align: left;

  padding: 27px 0;

  border-bottom: 2px solid ${props => props.theme.colors.azure};

  &:last-of-type {
    border: none !important;
  }

  & p {
    line-height: 1.3;
  }

  ${media.small`
    grid-gap: 6px;
  `}
  ${media.large`
    grid-row-gap: 10px;
  `}
`

const GetInTouchContainer = styled.div`
  width: 100%;
  max-width: 506px;

  display: grid;
  place-items: center;
  grid-row-gap: 27px;

  background-color: ${props => props.theme.colors.healerite};
  border-radius: 10px;

  color: ${props => props.theme.colors.azure};
  text-align: center;

  padding: 30px 53px 37px;

  ${media.small`
    margin: 63px auto 0 auto;
  `}
  ${media.large`
    margin: 93px auto 0 auto;
  `}

  & p {
    line-height: 40px;
  }

  & button {
    &:hover {
      background-color: white;
      color: ${props => props.theme.colors.azure};
    }
  }
`

const GreenGotham = styled(Gotham)`
  color: ${props => props.theme.colors.healerite};
  text-transform: uppercase;
`

const Opportunities = () => {
  const [loading, setLoading] = useState(true)
  const [currentJobListings, setCurrentJobListings] = useState([])

  const fetchLeverData = async () => {
    try {
      const leverEndpoint =
        'https://api.lever.co/v0/postings/innovationdept?mode=json'
      const { data } = await axios.get(leverEndpoint)

      const filteredJobListings = data
        .map(job => ({
          company: job.categories.department,
          position: job.text,
          team: job.categories.team,
          applyUrl: job.hostedUrl,
        }))
        .filter(job => job.company.toLowerCase() === 'innovation department')

      setCurrentJobListings(filteredJobListings)
    } catch (err) {
      new Error('Job Listing fetch error: ', err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchLeverData()
  }, [])

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const tl = gsap.timeline({
      defaults: { opacity: 0, duration: 0.75, y: 20 },
      scrollTrigger: '.opportunities__title',
    })
    tl.to('.opportunities__title, .opportunities__value-group', {
      visibility: 'visible',
    })
    tl.from('.opportunities__title', {})
    tl.from('.opportunities__job-listings-grid', { y: 0 })
    tl.from('.opportunities__get-in-touch-container', {}, '<0.5')
  }, [])

  return (
    <Container>
      <H1 className="opportunities__title">Current opportunities</H1>
      <JobListingGrid className="opportunities__job-listings-grid">
        {!loading &&
          currentJobListings.map(jobListing => (
            <JobRow key={jobListing.applyUrl}>
              <P>
                <b>{jobListing.position}</b>
              </P>
              <a
                href={jobListing.applyUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button>apply</Button>
              </a>
              <GreenGotham>{jobListing.team}</GreenGotham>
            </JobRow>
          ))}
      </JobListingGrid>

      <GetInTouchContainer className="opportunities__get-in-touch-container">
        <LargeP>Don’t see the position you’re looking for?</LargeP>
        <a
          href="mailto:info@innovationdept.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button>get in touch</Button>
        </a>
      </GetInTouchContainer>
    </Container>
  )
}

export default Opportunities
